import React from 'react';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import ToolPage from '../../../components/ToolPage';
import { setPageTitleAction } from '../../../actions/actions';
import TableauReportList from '../components/TableauReportList';

const pageTitle = 'Experimental Features';
const OKTA_ADMIN_GROUPS = window.config.OKTA_ADMIN_GROUPS || [];

class Experimental extends React.Component {
    componentDidMount() {
        if (this.props.pageTitle !== pageTitle) {
            this.props.setPageTitle(pageTitle);
        }
    }

    render() {
        return (
            <ToolPage
                toolName="TABLEAU Report Viewer"
                toolPath="/Experimental/"
                homeName="TABLEAU Report Viewer"
                initializeToolData={() => {}}
                checkToolData={() => {
                    return true;
                }}
                pageData={[]}
            >
                <TableauReportList />
            </ToolPage>
        );
    }
}

function mapStateToProps(state) {
    return {
        commonDialog: state.rootReducer.commonDialog,
        pageTitle: state.rootReducer.pageTitle,
        isMasterDataInitialized: state.masterReducer.isInitialized
    };
}

const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title))
});

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(Experimental));
