import React, { useState } from 'react';
import { DrcPanel, DrcButton } from '@driscollsinc/driscolls-react-components';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { DuThemeUtilities } from '@driscollsinc/driscolls-react-utilities';
import DownIcon from '@material-ui/icons/KeyboardArrowDown';
import UpIcon from '@material-ui/icons/KeyboardArrowUp';

const LIGHT_BRIGHTNESS = 80;
const DARK_BRIGHTNESS = 20;

const styles = () => ({
    container: {
        width: '100%',
        height: '100%',
        '@media print': {
            display: 'none'
        }
    },
    list: {
        width: '100%',
        padding: 0,
        margin: 0,
        paddingTop: '2rem',
        height: 'calc(100vh - 49px)'
    },
    menuItem: {
        margin: '0 !important',
        borderRadius: 'unset !important',
        border: 'none',
        justifyContent: 'start',
        letterSpacing: '1px'
    },
    parentMenuItem: {
        width: 'calc(100% - 33px) !important'
    },
    subLinkBtn: {
        width: '33px !important',
        minWidth: 33,
        '& > span': {
            fontSize: '2rem'
        }
    },
    subMenuItem: {
        width: '100% !important',
        paddingLeft: 24
    },
    selected: {
        backgroundColor: DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.green), LIGHT_BRIGHTNESS),
        '@media (prefers-color-scheme: dark)': {
            backgroundColor: DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.green), DARK_BRIGHTNESS)
        }
    }
});

// We can add this to style guide
function ToolNavigation(props) {
    const { classes, links, callback } = props;
    const [openField, setOpenField] = useState(null);

    const isSelected = (path) => {
        return path === window.location.pathname;
    };

    const handleSubLinkHeaderClick = (label) => {
        if (openField === label) {
            setOpenField(null);
        } else {
            setOpenField(label);
        }
    };

    return (
        <div className={classes.container}>
            <DrcPanel className={classes.list}>
                {(links || []).map((link, indx) => {
                    if (link.subLinks && link.subLinks.length > 0) {
                        return (
                            <div key={indx}>
                                <DrcButton
                                    className={`${classes.menuItem} ${classes.parentMenuItem} ${isSelected(link.path) ? classes.selected : ''}`}
                                    to={link.path}
                                    onClick={callback}
                                >
                                    {link.label}
                                </DrcButton>
                                <DrcButton
                                    className={`${classes.menuItem} ${classes.subLinkBtn}`}
                                    onClick={() => {
                                        handleSubLinkHeaderClick(link.label);
                                    }}
                                >
                                    <DownIcon style={openField === link.label ? { display: 'none' } : { display: 'block' }} />
                                    <UpIcon style={openField === link.label ? { display: 'block' } : { display: 'none' }} />
                                </DrcButton>
                                <div style={openField === link.label ? { display: 'block' } : { display: 'none' }}>
                                    {link.subLinks.map((subLink) => (
                                        <DrcButton
                                            key={subLink.label}
                                            className={`${classes.menuItem} ${classes.subMenuItem} ${
                                                isSelected(subLink.path) ? classes.selected : ''
                                            }`}
                                            to={subLink.path}
                                            onClick={callback}
                                        >
                                            {subLink.label}
                                        </DrcButton>
                                    ))}
                                </div>
                            </div>
                        );
                    }
                    return (
                        <DrcButton
                            key={indx}
                            className={`${classes.menuItem} ${isSelected(link.path) ? classes.selected : ''}`}
                            to={link.path}
                            onClick={callback}
                        >
                            {link.label}
                        </DrcButton>
                    );
                })}
            </DrcPanel>
        </div>
    );
}

export default withRouter(withStyles(styles)(ToolNavigation));
