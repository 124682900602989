import React from 'react';
import { withOktaAuth } from '@okta/okta-react';
import { withStyles } from '@material-ui/core/styles';
import { DrcPanel, DrcMediaQueries } from '@driscollsinc/driscolls-react-components';
import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';
import ServiceTool from './ServiceTool';
import { Translate } from 'react-localize-redux';
import FolderOpen from '@material-ui/icons/FolderOpen';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import PostAdd from '@material-ui/icons/PostAdd';
import School from '@material-ui/icons/School';
import FormatPaint from '@material-ui/icons/FormatPaint';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import DescriptionIcon from '@material-ui/icons/Description';
import ResourceAllocationIcon from '@material-ui/icons/AssignmentInd';
import AddAlertIcon from '@material-ui/icons/AddAlert';
import HealingIcon from '@material-ui/icons/Healing';

const TOOLS = [
    {
        id: 'tools.user',
        to: '/UserManagement/',
        icon: <SupervisorAccount />,
        requiredGroups: window.config.OKTA_USER_ADMIN || []
    },
    {
        id: 'tools.folder',
        to: '/FolderManagement/',
        icon: <FolderOpen />,
        requiredGroups: window.config.OKTA_FOLDER_ADMIN || []
    },
    {
        id: 'tools.resources',
        to: '/Resources/',
        icon: <PostAdd />,
        requiredGroups: window.config.OKTA_ALL || []
    },
    {
        id: 'tools.developerLearning',
        to: '/DeveloperLearning/',
        icon: <School />,
        requiredGroups: window.config.OKTA_DEV || [],
        hide: !window.location.host.includes('dev') && !window.location.host.includes('localhost')
    },
    {
        id: 'tools.styleGuide',
        to: '/StyleGuide/',
        icon: <FormatPaint />,
        requiredGroups: window.config.OKTA_DEV || []
    },
    {
        id: 'tools.releaseManagement',
        to: '/Release/',
        icon: <NewReleasesIcon />,
        requiredGroups: window.config.OKTA_ALL || [],
        hide: !window.location.host.includes('dev') && !window.location.host.includes('localhost')
    },
    {
        id: 'tools.documentCreation',
        to: '/DocumentCreation/',
        icon: <DescriptionIcon />,
        requiredGroups: window.config.OKTA_DOCUMENT_CREATION || []
    },
    {
        id: 'tools.resourceAllocation',
        to: '/ResourceAllocations/',
        icon: <ResourceAllocationIcon />,
        requiredGroups: window.config.OKTA_DEV_ADMIN || []
    },
    {
        id: 'tools.triggers',
        to: '/Triggers/',
        icon: <AddAlertIcon />,
        requiredGroups: window.config.OKTA_GS_ADMIN || []
    },
    {
        id: 'tools.disasterRecovery',
        to: '/DisasterRecovery/',
        icon: <HealingIcon />,
        requiredGroups: window.config.OKTA_DISASTER_ADMIN || []
    },
    {
        id: 'tools.experimental',
        to: '/Experimental/',
        icon: <EmojiObjectsIcon />,
        requiredGroups: window.config.OKTA_ADMIN_GROUPS || []
    }
    /*,
    {
        id: 'tools.urlShortener',
        to: '/UrlShortener/',
        icon: <UrlIcon />,
        requiredGroups: window.config.OKTA_ADMIN_GROUPS || [],
        hide: !window.location.host.includes('dev') && !window.location.host.includes('localhost')
    }*/
];

const styles = () => ({
    container: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translateY(-50%) translateX(-50%)',
        width: '100%',
        background: 'none',
        border: 'none',
        ['@media ' + DrcMediaQueries.mobileL]: {
            bottom: 0,
            top: 'inherit',
            transform: 'translateY(0) translateX(-50%)',
            padding: 0
        }
    },
    containerFloating: {
        position: 'relative',
        display: 'inline-block',
        margin: 0
    }
});

class ServiceTools extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tools: []
        };
    }

    async componentDidMount() {
        await this.determineTools();
    }

    async componentDidUpdate() {
        await this.determineTools();
    }

    determineTools = async () => {
        let token = this.props.authState?.accessToken;

        if (token) {
            let tools = TOOLS.filter((tool) => DuAuthenticationUtilities.IsInGroup(token, tool.requiredGroups || []) && !tool.hide);

            this.setTools(tools);
        } else {
            this.setTools([]);
        }
    };

    setTools = (tools) => {
        if ((tools || []).length !== this.state.tools.length) {
            this.setState({ tools });
        }
    };

    render() {
        const { isFloating, onClose, classes } = this.props;

        return (
            <DrcPanel maxWidth={700} className={isFloating ? classes.containerFloating : classes.container}>
                <div className="row">
                    {this.state.tools.map((tool) => (
                        <ServiceTool
                            key={`${isFloating ? 'float' : 'sta'}${tool.id}`}
                            text={<Translate id={tool.id} />}
                            to={tool.to}
                            color={tool.color}
                            onClick={onClose}
                        >
                            {tool.icon}
                        </ServiceTool>
                    ))}
                </div>
            </DrcPanel>
        );
    }
}

export default withOktaAuth(withStyles(styles)(ServiceTools));
