import React from 'react';
import ToolNavigation from './ToolNavigation';
import { connect } from 'react-redux';
import { Switch, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { setPageTitleAction } from '../actions/actions';
import MasterDataUtilities from '../data/MasterDataUtilities';
import { DrcPanel, DrcSecureGroupRoute, DrcProgress, DrcButton, DrcMediaQueries, DrcIconButton } from '@driscollsinc/driscolls-react-components';
import Warning from '@material-ui/icons/Warning';
import { SecureRoute } from '@okta/okta-react';
import BusLoading from './BusLoading';
import { DuThemeUtilities } from '@driscollsinc/driscolls-react-utilities';
import MenuIcon from '@material-ui/icons/MenuOpen';

const styles = () => ({
    container: {
        marginTop: 49,
        height: 'calc(100vh - 49px)',
        '@media print': {
            marginTop: 0,
            height: 'fit-content'
        }
    },
    sidebar: {
        width: '15%',
        float: 'left',
        minWidth: 200,
        ['@media ' + DrcMediaQueries.mobileL]: {
            width: '100%',
            minWidth: '100%',
            float: 'none',
            position: 'absolute',
            display: 'none',
            top: 49,
            zIndex: 10
        }
    },
    toolNavOpen: {
        display: 'block'
    },
    content: {
        float: 'right',
        width: 'min(85%, calc(100% - 200px))',
        maxHeight: 'calc(100vh - 49px)',
        overflowY: 'auto',
        ['@media ' + DrcMediaQueries.mobileL]: {
            width: '100%',
            float: 'none',
            paddingBottom: 50
        },
        '@media print': {
            width: '100%',
            float: 'none',
            maxHeight: 'fit-content',
            paddingBottom: 0
        }
    },
    menuBtn: {
        position: 'absolute',
        bottom: 10,
        display: 'none',
        left: 'calc(50vw - 28px)',
        width: 40,
        height: 40,
        zIndex: 11,
        '& svg': {
            width: 30,
            height: 30,
            position: 'absolute',
            marginTop: -14,
            marginLeft: -14
        },
        ['@media ' + DrcMediaQueries.mobileL]: {
            display: 'block'
        },
        '@media print': {
            display: 'none'
        }
    }
});

const iconStyle = {
    fontSize: '4rem'
};

const labelStyle = {
    margin: '20px 20px 0 20px',
    position: 'relative',
    top: '-15px'
};

class ToolPage extends React.Component {
    state = {
        isLoading: false,
        isError: false,
        toolDataMessage: '',
        toolDataCallCount: 0,
        toolDataCallIndex: 0,
        toolDataCallIsError: false,
        routes: <React.Fragment />,
        toolNavOpen: false
    };

    componentDidMount() {
        const { toolName, toolPath, pageData, children } = this.props;

        this.setState({
            routes: [
                <SecureRoute key={toolPath + 'Home'} exact path={`${toolPath}`} render={() => children} />,
                ...pageData.map((page) => (
                    <DrcSecureGroupRoute
                        key={page.path}
                        path={`${toolPath}${page.path}`}
                        exact={!!page.exact}
                        component={page.component}
                        groupsAllowed={page.allowedGroups}
                    />
                )),
                <SecureRoute
                    key={toolPath + '404'}
                    path={`${toolPath}`}
                    render={() => (
                        <DrcPanel error maxWidth="400px" style={{ textAlign: 'center', marginTop: 24 }}>
                            <Warning style={iconStyle} />
                            <h1 style={labelStyle}>
                                404 Page not found in:
                                <br />
                                {toolName}
                            </h1>
                            <Warning style={iconStyle} />
                        </DrcPanel>
                    )}
                />
            ]
        });
    }

    async componentDidUpdate() {
        const { checkToolData, initializeToolData } = this.props;

        if (typeof initializeToolData === 'function' && typeof checkToolData === 'function') {
            if (!this.state.isLoading && !checkToolData()) {
                await this.setState({ isLoading: true });

                await initializeToolData((message, totalCalls, currentCallIndex, isError) => {
                    this.setState({
                        toolDataMessage: message,
                        toolDataCallCount: totalCalls,
                        toolDataCallIndex: currentCallIndex,
                        toolDataCallIsError: isError
                    });

                    if (currentCallIndex >= totalCalls) {
                        this.setState({ isLoading: false, isError });
                    }
                });
            }
        }
    }

    toggleMenu = () => {
        this.setState({ toolNavOpen: !this.state.toolNavOpen });
    };

    render() {
        const { classes, toolPath, homeName, pageData, isMasterDataInitialized, subLinks } = this.props;
        const { isLoading, isError, routes, toolDataMessage, toolDataCallCount, toolDataCallIndex, toolDataCallIsError, toolNavOpen } = this.state;
        const PERCENT_CONVERSION = 100;

        if (!MasterDataUtilities.Check(isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }

        return (
            <div className={classes.container}>
                <div className={classes.content}>
                    <DrcPanel maxWidth="400px" style={{ textAlign: 'center', marginTop: 24, display: isLoading || isError ? 'block' : 'none' }}>
                        <h1 style={labelStyle}>Loading {homeName} Data:</h1>
                        <p style={toolDataCallIsError ? { color: DuThemeUtilities.DefaultColors.primary.red } : {}}>{toolDataMessage}</p>
                        <BusLoading text=" " />
                        <DrcProgress
                            value={toolDataCallCount > 0 && toolDataCallIndex >= 0 ? Math.round((PERCENT_CONVERSION * toolDataCallIndex) / toolDataCallCount) : 0}
                        />
                        <DrcButton
                            style={{ display: isError ? 'block' : 'none' }}
                            isPrimary
                            fullWidth
                            onClick={() => {
                                this.setState({ isError: false });
                            }}
                        >
                            Continue With Errors
                        </DrcButton>
                    </DrcPanel>
                    <div style={{ display: isLoading || isError ? 'none' : 'block' }}>
                        <Switch>{isLoading ? null : routes}</Switch>
                    </div>
                </div>
                <div className={`${classes.sidebar} ${toolNavOpen ? classes.toolNavOpen : ''}`}>
                    <ToolNavigation
                        links={
                            isLoading || isError
                                ? []
                                : [
                                      {
                                          label: homeName,
                                          path: toolPath,
                                          subLinks:
                                              subLinks && subLinks.length > 0
                                                  ? subLinks.map((sl) => {
                                                        return { label: sl.label, path: `${toolPath}${sl.to}` };
                                                    })
                                                  : null
                                      },
                                      ...pageData
                                          .filter((page) => page.showLink)
                                          .map((page) => {
                                              let pageDataSubLinks = page.subLinks;

                                              if (pageDataSubLinks && pageDataSubLinks.length > 0) {
                                                pageDataSubLinks = pageDataSubLinks.map((sl) => {
                                                      return { label: sl.label, path: `${toolPath}${sl.to}` };
                                                  });
                                              }

                                              return { label: page.label, path: `${toolPath}${page.to}`, subLinks: pageDataSubLinks };
                                          })
                                  ]
                        }
                        callback={() => {
                            this.setState({ toolNavOpen: false });
                        }}
                    />
                </div>
                <DrcIconButton className={`${classes.menuBtn}`} onClick={this.toggleMenu}>
                    <MenuIcon />
                </DrcIconButton>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        commonDialog: state.rootReducer.commonDialog,
        pageTitle: state.rootReducer.pageTitle,
        isMasterDataInitialized: state.masterReducer.isInitialized
    };
}

const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(ToolPage)));
