import React, { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { makeStyles } from '@material-ui/core/styles';
import { DrcButton, DrcLegend, DrcPanel, DrcInput } from '@driscollsinc/driscolls-react-components';
import TableauReport from 'tableau-react';

const useStyles = makeStyles({
    addBtn: {
        padding: 8,
        margin: 0
    }
});

const FAKE_DATA = [
    {
        url: 'PBA',
        name: { en: 'Pallet Builder', es: 'Armado de Tarimas' },
        description: {
            en: 'Link to Pallet Builder for different device types.',
            es: 'Enlace a Armado de Tarimas para diferentes tipos de dispositivos.'
        },
        android: {
            en: 'https://play.google.com/store/apps/details?id=com.driscollsinc.palletbuilder',
            es: 'https://play.google.com/store/apps/details?id=com.driscollsinc.palletbuilder&hl=es&gl=US'
        },
        ios: {
            en: 'https://apps.apple.com/us/app/pallet-builder/id1553994834',
            es: 'https://apps.apple.com/mx/app/pallet-builder/id1553994834'
        },
        windows: '',
        mac: '',
        default: ''
    },
    {
        url: 'ABCD',
        name: 'Simple Redirect',
        description: 'Simply Loads Google',
        android: '',
        ios: '',
        windows: '',
        mac: '',
        default: 'https://www.google.com/'
    }
];

function TableauReportList() {
    const { authState } = useOktaAuth();
    const classes = useStyles();

    const [searchText, setSearchText] = useState('');
    const [reportUrl, setReportUrl] = useState(null);
    const [urls, setUrls] = useState([...FAKE_DATA]);

    const toProtectLowerCase = (value) => (value || '').toLowerCase();

    const findMatch = (value, search) => {
        if (typeof value === 'string') {
            return toProtectLowerCase(value).includes(search);
        } else if (typeof value === 'object') {
            return Object.keys(value).findIndex((v) => toProtectLowerCase(value[v]).includes(search)) >= 0;
        } else {
            return false;
        }
    };

    useEffect(() => {
        let search = searchText.toLowerCase();

        setUrls(FAKE_DATA.filter((url) => findMatch(url.url, search) || findMatch(url.name, search) || findMatch(url.description, search)));
    }, [searchText]);

    const loadTableauReport = () => {
        setReportUrl(searchText);
    };

    return (
        <DrcPanel maxWidth="1500px">
            <DrcLegend>TABLEAU REPORT VIEWER</DrcLegend>
            <div className="row">
                <DrcInput
                    className="col-xs-10"
                    label="Report URL"
                    placeholder="URL"
                    onChange={(e) => {
                        setSearchText(e?.target?.value || '');
                    }}
                ></DrcInput>
                <div className="col-xs-2">
                    <DrcButton
                        className={classes.addBtn}
                        isPrimary
                        fullWidth
                        onClick={() => {
                            loadTableauReport();
                        }}
                    >
                        LOAD REPORT
                    </DrcButton>
                </div>
            </div>
            <div className="row">{reportUrl && <TableauReport url={reportUrl} />}</div>
        </DrcPanel>
    );
}

export default TableauReportList;
