import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Translate, withLocalize } from 'react-localize-redux';
import { DrcMain, DrcImage, DrcSwitch, DrcInput, DrcButton, DrcDatePicker } from '@driscollsinc/driscolls-react-components';
import Slider from '@material-ui/core/Slider';
import { RatingStar } from "rating-star";
import BackgroundPng from '../Images/Bowl_Mixed_3.png';
import BackgroundWebP from '../Images/Bowl_Mixed_3.webp';
import FancyBlackPng from '../Images/Fancy_Black.png';
import FancyBlackWebP from '../Images/Fancy_Black.webp';
import FancyBluePng from '../Images/Fancy_Blue.png';
import FancyBlueWebP from '../Images/Fancy_Blue.webp';
import FancyRaspPng from '../Images/Fancy_Rasp.png';
import FancyRaspWebP from '../Images/Fancy_Rasp.webp';
import FancyStrawPng from '../Images/Fancy_Straw.png';
import FancyStrawWebP from '../Images/Fancy_Straw.webp';

const BERRY_TYPES = {
    'black': 'BLACK',
    'blue': 'BLUE',
    'rasp': 'RASP',
    'straw': 'STRAW'
}

const BERRY_BACKGROUNDS = {
    'black': {
        png: FancyBlackPng,
        webp: FancyBlackWebP
    },
    'blue': {
        png: FancyBluePng,
        webp: FancyBlueWebP
    },
    'rasp': {
        png: FancyRaspPng,
        webp: FancyRaspWebP
    },
    'straw': {
        png: FancyStrawPng,
        webp: FancyStrawWebP
    }
};

const QUESTION_TYPES = {
    TEXT_SINGLE: 'TEXT_SINGLE',
    TEXT_MULTI: 'TEXT_MULTI',
    NUMBER: 'NUMBER',
    STARS: 'STARS',
    RANGE: 'RANGE',
    BOOLEAN: 'BOOLEAN',
    DATE: 'DATE'
};

const useStyles = makeStyles({
    main: {
        width: '80vw'
    },
    title: {
        textAlign: 'center',
        fontFamily: 'BlogScript',
        fontSize: '3rem',
        fontWeight: 'normal',
        margin: 0
    },
    tagline: {
        textAlign: 'center',
        fontStyle: 'italic',
        marginBottom: 32
    },
    row: {
        padding: 20
    },
    label: {
        fontSize: '2rem',
        marginBottom: 10,
        '& > span': {
            fontSize: '2rem'
        }
    },
    switch: {
        '& > span': {
            marginLeft: -12
        }
    },
    notes: {
        '& .MuiInputBase-root': {
            height: 'inherit'
        }
    },
    submit: {
        width: '100%',
        maxWidth: 300,
        margin: '20px auto 0px',
        display: 'block'
    }
});
//[en,es,zh,fr]
const DATE_PLACEHOLDER = ['Select a Date', 'Seleccione una fecha', '选择一个日期', 'Sélectionnez une date'];
const MULTI_PLACEHOLDER = ['Enter your answer', 'Ingrese su respuesta', '输入你的答案', 'Entrez votre réponse'];

function BerrySurvey(props) {
    const { berryId } = useParams();
    const classes = useStyles();

    const language = useSelector((state) => state.localize.languages.find((lang) => lang.active));

    const [content, setContent] = useState({
        isLoaded: false,
        title: 'Loading Survey',
        note: '',
        backgroundPng: BackgroundPng,
        backgroundWebP: BackgroundWebP
    });

    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState([]);

    const determineContent = (berryType) => {
        if (!Object.keys(BERRY_TYPES).includes(berryType)) {
            return {
                singularBerryType: 'Berry',
                pluralBerryType: 'Berries'
            }
        }

        setContent({
            isLoaded: true,
            title: <><Translate id={`berries.${berryType}`} /> <Translate id='survey.survey' /></>,
            note: <><Translate id='survey.tagline' /> <Translate id={`berries.${berryType}s`} /></>,
            backgroundPng: BERRY_BACKGROUNDS[berryType].png,
            backgroundWebP: BERRY_BACKGROUNDS[berryType].webp
        });

        return {
            singularBerryType: <Translate id={`berries.${berryType}`} />,
            pluralBerryType: <Translate id={`berries.${berryType}s`} />
        };
    }

    const getQuestionContainer = (key, label, description, question, className) => {
        return <div className={`row ${classes.row}`} key={key}>
            <div className='col-md-12 col-lg-8'>
                <div className={classes.label}>{label}</div>
                {description ? <div>{description}</div> : null}
            </div>
            <div className={className ? className : 'col-md-12 col-lg-4'}>
                {question}
            </div>
        </div>
    };

    const getTranslatedValue = (input, translatedValues) => {
        if(input && Array.isArray(input)) {
            let index = props.languages.findIndex(l => l.code == props.activeLanguage.code);
            let translatedValue = input[index >= 0 ? index >= input.length ? 0 : index : 0];

            Object.keys(translatedValues).forEach(val => {
                var parts = translatedValue.split(`\${${val}}`);
                if (parts.length > 1) {
                    parts = parts.map((p, i) => { return <>{p}{i + 1 < parts.length ? translatedValues[val] : null}</> });
                    translatedValue = parts;
                }
            });
            return translatedValue;
        }

        return input;
    };

    const getQuestionComponent = (key, type, label, description, translatedValues, settings = {}) => {
        const translatedLabel = getTranslatedValue(label, translatedValues);
        const translatedDescription = getTranslatedValue(description, translatedValues);

        if (type == QUESTION_TYPES.TEXT_SINGLE) {
            return getQuestionContainer(key, translatedLabel, translatedDescription, (
                <DrcInput
                    name={key}
                    label={settings.label || null}
                    placeholder={settings.placeholder || 'Enter your answer'}
                    value={answers[key] || settings.defaultValue || ''}
                    onChange={(e) => { setAnswers({ ...answers, [key]: e.currentTarget.value }) }}
                />));
        } else if (type == QUESTION_TYPES.TEXT_MULTI) {
            const placeHolder = getTranslatedValue(MULTI_PLACEHOLDER, translatedValues);

            return getQuestionContainer(key, translatedLabel, translatedDescription, (
                <DrcInput
                    name={key}
                    className={classes.notes}
                    label={settings.label || null}
                    placeholder={settings.placeholder || placeHolder}
                    value={answers[key] || settings.defaultValue || ''}
                    onChange={(e) => { setAnswers({ ...answers, [key]: e.currentTarget.value }) }}
                    multiline
                    rows={settings.rows || 4}
                />),
                'col-sm-12');
        } else if (type == QUESTION_TYPES.STARS) {
            let starRange = settings.range || [1, 5];

            return getQuestionContainer(key, translatedLabel, translatedDescription, (
                <RatingStar
                    clickable
                    maxScore={starRange[1]}
                    numberOfStar={starRange[1]}
                    id={key}
                    label={settings.label || null}
                    rating={answers[key] || settings.defaultValue || 0}
                    onRatingChange={(rating) => { setAnswers({ ...answers, [key]: rating }) }}
                />));
        } else if (type == QUESTION_TYPES.NUMBER) {
            return getQuestionContainer(key, translatedLabel, translatedDescription, (
                <DrcInput
                    name={key}
                    type='number'
                    label={settings.label || null}
                    placeholder={settings.placeholder || 'Enter your answer'}
                    value={answers[key] || settings.defaultValue || ''}
                    onChange={(val) => { setAnswers({ ...answers, [key]: val }) }}
                />));
        } else if (type == QUESTION_TYPES.DATE) {
            const placeHolder = getTranslatedValue(DATE_PLACEHOLDER, translatedValues);

            return getQuestionContainer(key, translatedLabel, translatedDescription, (
                <DrcDatePicker
                    name={key}
                    className=''
                    label={settings.label || placeHolder}
                    placeholder={settings.placeholder || placeHolder}
                    value={answers[key] || settings.defaultValue || null}
                    onChange={(val) => { setAnswers({ ...answers, [key]: val }) }}
                />));
        }
    };

    const getQuestions = (singularBerryType, pluralBerryType) => {
        let translatedValues = {
            singularBerryType,
            pluralBerryType
        }
        //TODO: call API on load to determine questions to ask based on berry type and id scanned probably
        //for now create a hardcoded list of questions related to quality, flavor, and date purchased
        setQuestions([
            getQuestionComponent(
                'quality',
                QUESTION_TYPES.STARS,
                <Translate id="survey.quality" data={{ pluralBerryType }} options={{ renderInnerHtml: false }} />,
                <Translate id="survey.qualityDescription" data={{ pluralBerryType }} options={{ renderInnerHtml: false }} />,
                translatedValues,
                { range: [1, 5] }),
            getQuestionComponent(
                'flavor',
                QUESTION_TYPES.STARS,
                <Translate id="survey.flavor" data={{ pluralBerryType }} options={{ renderInnerHtml: false }} />,
                <Translate id="survey.flavorDescription" data={{ pluralBerryType }} options={{ renderInnerHtml: false }} />,
                translatedValues,
                { range: [1, 5] }),
            getQuestionComponent(
                'date',
                QUESTION_TYPES.DATE,
                <Translate id="survey.dateOfPurchase" />,
                <Translate id="survey.dateOfPurchaseDescription" data={{ pluralBerryType }} options={{ renderInnerHtml: false }} />,
                translatedValues),
            getQuestionComponent(
                'notes',
                QUESTION_TYPES.TEXT_MULTI,
                <Translate id="survey.moreToAdd" />,
                null,
                translatedValues)
        ])
    };

    useEffect(() => {
        let berryType = 'Unknown';

        if (berryId == 1 || 'Blackberry') {
            berryType = 'black';
        }
        else if (berryId == 2 || 'Blueberry') {
            berryType = 'blue';
        }
        else if (berryId == 3 || 'Raspberry') {
            berryType = 'rasp';
        }
        else if (berryId == 4 || 'Strawberry') {
            berryType = 'straw';
        }

        let berryTypeInfo = determineContent('black');
        getQuestions(berryTypeInfo.singularBerryType, berryTypeInfo.pluralBerryType);
    }, [berryId, answers, props.activeLanguage]);

    return (
        <DrcMain maxWidth={700} className={classes.main}>
            <DrcImage
                src={content.backgroundPng}
                webp={content.backgroundWebP}
                style={{
                    position: 'absolute',
                    height: '100vh',
                    width: '100vw',
                    top: 0,
                    left: 0,
                    objectFit: 'cover',
                    zIndex: -5
                }}
                alt=""
            />
            <h1 className={classes.title}>{content.title}</h1>
            <div className={classes.tagline}>{content.note}</div>
            <div style={content.isLoaded ? {display: 'block'} : {display: 'none'}}>
                {questions && questions.map((question) => {
                    return question;
                })}
                <DrcButton isPrimary noStyle className={classes.submit}>
                    <Translate id="buttonTempSubmit" />
                </DrcButton>
            </div>
        </DrcMain>
    );
}

export default withLocalize(BerrySurvey);
